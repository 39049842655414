<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M15.1481 5.1327C14.5033 4.69288 13.7758 4.36585 12.9946 4.18V3.5C12.9946 3.10218 12.8366 2.72064 12.5555 2.43934C12.2743 2.15804 11.893 2 11.4954 2C11.0978 2 10.7165 2.15804 10.4353 2.43934C10.1542 2.72064 9.99622 3.10218 9.99622 3.5V4.18C7.12777 4.86 4.99892 7.43 4.99892 10.5V16L3 18V19H19.9908V18L17.9919 16V13.9881L15.993 15.9881V17H6.99784V10.5C6.99784 8 8.99676 6 11.4954 6C12.3015 6 13.0557 6.20818 13.7074 6.57416L15.1481 5.1327Z"
      fill="#D4D6D9"
    />
    <path
      d="M21.8889 5.84636L20.2922 4.24877L12.2583 12.287L9.90551 9.93298L8.30732 11.532L12.2568 15.4837L21.8889 5.84636Z"
      fill="#D4D6D9"
    />
    <path
      d="M11.4954 22C12.0256 22 12.534 21.7893 12.9089 21.4142C13.2837 21.0391 13.4943 20.5304 13.4943 20H9.49649C9.49649 20.5304 9.70709 21.0391 10.082 21.4142C10.4568 21.7893 10.9653 22 11.4954 22Z"
      fill="#D4D6D9"
    />
  </svg>
</template>
