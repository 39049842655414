import type { ReviewsProductSurveyResponseDetailsQuery, ReviewsProductSurveyResponsesQuery } from '@/gql/reviews'

export type SurveyResponse = NonNullable<NonNullable<ReviewsProductSurveyResponsesQuery['manage']>['surveyResponses']>['results'][number]
export type SurveyResponseDetails = NonNullable<NonNullable<ReviewsProductSurveyResponseDetailsQuery['manage']>['surveyResponse']>
export type SurveyResponseReminder = SurveyResponseDetails['reminders'][number]

export type SurveyResponseReviewReply = NonNullable<SurveyResponseDetails['review']>['reviewReply']

export enum ReviewInteractionType {
  REMINDER = 'reminder',
  REPLY = 'reply',
}
