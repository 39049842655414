<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M16.2304 2.00275H3.01043C2.45756 2.00275 1.98427 2.19855 1.59056 2.59017C1.19685 2.98178 1 3.45254 1 4.00247V22L5.02086 18.0005H19.0939C19.6467 18.0005 20.12 17.8047 20.5137 17.4131C20.9074 17.0215 21.1043 16.5507 21.1043 16.0008V8.77894L19.0939 10.7558V16.0008H4.16643L3.01043 17.1256V4.00247H14.2199L16.2304 2.00275Z"
      fill="#D4D6D9"
    />
    <path
      d="M11.3585 6.38337L9.74238 7.99088L13.7248 11.9521L23.4211 2.30743L21.805 0.699951L13.7248 8.73706L11.3585 6.38337Z"
      fill="#D4D6D9"
    />
  </svg>
</template>
